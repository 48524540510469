<!--  -->
<template>
<div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },

  components: {},

  computed: {},

  mounted() {
    
  },

  methods: {},
};

</script>
<style lang='scss' scoped>
</style>
