<template>
  <div class="revoke-order-index-page">
    <div class="search-and-operated-record flexbox flex-lr flex-align-center flex-justify-between">
      <div class="search-frame flexbox flex-lr flex-align-center">
        <el-input type="text" size="middle" v-model="orderId" clearable
                  class="search-frame-input" placeholder="输入订单/配送单号查询"></el-input>
        <el-button type="primary" class="search-frame-btn" @click="search">查询</el-button>
      </div>
      <div class="operated-record" @click="checkRecordList">操作记录</div>
    </div>
    <div class="revoke-order-tips">注：只针对配送中的订单进行撤销</div>
    <div class="revoke-order-table">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="orderSource" label="订单来源" width="180">
          <template slot-scope="scope">
            <span>{{scope.row.orderSource | filter_orderSource}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="merchantName" label="店铺名称" width="180"></el-table-column>
        <el-table-column prop="orderRentTime" label="下单时间"></el-table-column>
        <el-table-column prop="deliveryId" label="订单号"></el-table-column>
        <el-table-column prop="skOrderId" label="配送单号"></el-table-column>
        <el-table-column prop="riderName" label="配送骑手"></el-table-column>
        <el-table-column prop="agentName" label="骑手归属代理商"></el-table-column>
        <el-table-column prop="state" label="配送状态">
          <template slot-scope="scope">
            <span>{{scope.row.state | filter_state}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="operate-style" @click="clickRevoke(scope.row.skOrderId)">撤销配送</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="撤销配送单" :visible.sync="visible" center width="700px"
               :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="revoke-order-dialog-con">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="撤销原因：" prop="reason">
            <el-input type="textarea" :row="8" v-model="form.reason" show-word-limit :maxlength="100"
                      placeholder="请输入撤销原因" class="reason-input"></el-input>
          </el-form-item>
          <el-form-item label="操作人：" prop="name">
            <el-input v-model="form.name" :maxlength="10" placeholder="请输入操作人"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog title="操作记录" :visible.sync="visibleRecord" center width="1200px"
               :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="revoke-order-record-con">
        <div class="search-record-frame flexbox flex-lr flex-align-center">
          <el-input type="text" size="middle" v-model="recordOrderId" clearable
                    class="search-record-frame-input" placeholder="输入订单/配送单号查询"></el-input>
          <el-button type="primary" class="search-frame-btn" @click="searchRecord">查询</el-button>
        </div>
        <el-table :data="recordTableData" border max-height="400" style="width: 100%;">
          <el-table-column prop="createTime" label="操作时间" width="180"></el-table-column>
          <el-table-column prop="skOrderId" label="配送单号"></el-table-column>
          <el-table-column prop="cancelReason" label="撤销原因"></el-table-column>
          <el-table-column prop="cancelPerson" label="操作人" width="120"></el-table-column>
        </el-table>
        <el-pagination class="mt10" background :page-count="totalPage"
                       :current-page.sync="currentPage" @current-change="pageChange">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { $sg_selectRevokeOrder, $sg_moveRevokeOrder, $sg_selectCancelLog } from '@/api/order';

export default {
  name: 'revokeOrder',
  data() {
    return {
      orderId: '',
      tableData: [],
      revokeSkOrderId: '',
      visible: false,
      form: {
        reason: '',
        name: '',
      },
      rules: {
        reason: [
          {
            required: true,
            message: '请输入撤销原因',
            trigger: 'change',
          },
          {
            min: 1,
            max: 100,
            message: '长度在 1 到 100 个字符',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '请输入操作人',
            trigger: 'blur',
          },
        ],
      },
      recordOrderId: '',
      visibleRecord: false,
      totalPage: 1,
      currentPage: 1,
      recordTableData: [],
    };
  },
  filters: {
    filter_state(val) {
      switch (val) {
        case 1:
          return '待接单';
        case 2:
          return '已接单';
        case 3:
          return '已到店';
        case 4:
          return '配送中';
        case 5:
          return '配送完成';
        case 6:
          return '撤销订单';
        default:
          return '';
      }
    },
    filter_orderSource(val) {
      switch (val) {
        case 1:
          return '自营';
        case 2:
          return '三方平台';
        default:
          return '';
      }
    },
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  methods: {
    getList() {
      $sg_selectRevokeOrder({ orderId: this.orderId }).then((res) => {
        this.tableData = res;
      });
    },
    search() {
      this.getList();
    },
    clickRevoke(id) {
      this.revokeSkOrderId = id;
      this.visible = true;
    },
    cancel() {
      this.visible = false;
      this.$refs.form.resetFields();
      // this.form = {
      //   reason: '',
      //   name: '',
      // };
    },
    submit() {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            cancelPerson: this.form.name,
            cancelReason: this.form.reason,
            skOrderId: this.revokeSkOrderId,
            password: 'sssk2021',
          };
          $sg_moveRevokeOrder(params).then(() => {
            this.$message.success('撤销成功');
            this.getList();
          }).catch((err) => {
            this.$message.error(err.msg);
          }).finally(() => {
            this.cancel();
          });
        } else {
          return false;
        }
      });
    },
    checkRecordList() {
      this.visibleRecord = true;
      this.getRecordList();
    },
    searchRecord() {
      this.currentPage = 1;
      this.getRecordList();
    },
    getRecordList() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 10,
        skOrderId: this.recordOrderId,
      };
      $sg_selectCancelLog(params).then((res) => {
        this.recordTableData = res.records;
        this.totalPage = res.pages;
      });
    },
    pageChange() {
      this.getRecordList();
    },
  },
};
</script>
<style lang="scss">
#RevokeOrder>div{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
<style scoped lang="scss">
@import "@/style/var.scss";
.center-container .revoke-order-index-page{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  .search-and-operated-record ::v-deep{
    .search-frame{
      .search-frame-input{
        margin: 0 10px 0 0;
        .el-input__inner{
          width: 300px;
        }
      }
      .search-frame-btn{}
    }
    .operated-record{
      font-size: 16px;
      color: $--theme-color;
    }
  }
  .revoke-order-tips{
    font-size: 14px;
    color: $--theme-color;
    margin: 20px 0 0 0;
  }
  .revoke-order-table{
    .operate-style{
      color: $--theme-color;
    }
  }
  .revoke-order-dialog-con ::v-deep{
    .reason-input{
      .el-textarea__inner{
        height: 180px;
      }
    }
  }
  .revoke-order-record-con ::v-deep{
    .search-record-frame{
      margin: 0 0 20px 0;
      .search-record-frame-input{
        width: 300px;
        margin: 0 10px 0 0;
        .el-input__inner{
          width: 300px;
        }
      }
      .search-frame-btn{}
    }
  }
}
</style>
